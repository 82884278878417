import './Navbar.css';

export default function Navbar({footer, isMobileMenuOpen}) {

    if (footer === true) {
        return (
            <menu className="navbar isFooter" >
                <a href="/">Home</a>
                <a href="/about-us">About Us</a>
                <a href="/services">Our Comprehensive Services</a>
                <a href="/training">Security and CTPAT / OEA Training Services</a>
                <a href="/contact">Contact Us</a>
            </menu>
        );
    }

    return (
        <>
            <menu className={isMobileMenuOpen ? "navbar" : "navbar hideText"}>
                <a href="/">Home</a>
                <a href="/about-us">About Us</a>
                <a href="/services">Our Comprehensive Services</a>
                <a href="/training">Security and CTPAT / OEA Training Services</a>
                <a href="/contact">Contact Us</a>
            </menu>
        </>
    );
}
import Element from '../Element/Element';

import './ChooseUs.css';

export default function ChooseUs() {

    const lockIcon = require('../../utils/lock.png');
    const intTradeIcon = require('../../utils/intl-trade.png');
    const checkListIcon = require('../../utils/checklist-paper.png');
    const invoiceIcon = require('../../utils/invoice.png');
    const americaIcon = require('../../utils/america.png');

    return (
        <section className="choose-us">
            <h1>Why Choose Us</h1>
            <p>Your Trusted IMMEX Partner in Global Trade</p>
            <div className='elements'>
                <Element 
                title="Securing Your Supply Chain"
                description="Our team of experts specializes in safeguarding your Maquiladora operation in Mexico, ensuring the safety and efficiency of your operations. We’re committed to protecting your assets."
                icon={lockIcon} 
                iconDescription="icon of a lock" />
                <Element 
                title="Navigating International 
                Trade"
                description="With a deep understanding of IMMEX regulations and Free Trade Agreements, we guide you through the complexities of international commerce."
                icon={intTradeIcon} 
                iconDescription="icon of the earth with an arrow around it" />
                <Element 
                title="Compliance Assurance"
                description="Our professionals conduct customs audits, compliance reviews, and offer expertise in IMMEX/PROSEC, VAT certification, and annual customs compliance audits. We’ve successfully assisted numerous companies in managing customs audits and requests."
                icon={checkListIcon} 
                iconDescription="icon of a checklist and a pencil" />
                <Element 
                title="Duty Deferral Programs"
                description="We’re skilled in optimizing your cost-saving opportunities through duty deferral programs like USMCA/EU and PROSEC."
                icon={invoiceIcon} 
                iconDescription="icon of a receipt being printed" />
                <Element 
                title="CTPAT / OEA"
                description="Our proficiency extends to CTPAT/OEA implementation, applications, training, and compliance audits."
                icon={americaIcon} 
                iconDescription="icon of north and south america" />
            </div>

            <div className="get-started">
                <h2>GET STARTED</h2>
                <div className='content'>
                    <p>Explore our services, learn more about us, and discover how we can fortify your Maquiladora operation across all of Mexico while ensuring compliance. Get in touch today to start a partnership that leads to excellence.</p>
                    <button id='contact-us-button'><b>CONTACT US</b></button> 
                </div> 
            </div>
        </section>
    );
}
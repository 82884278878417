import './App.css';
import Header from './Components/Header/Header.js';
import HomePage from './Components/HomePage/HomePage.js';
import AboutUsPage from './Components/AboutUsPage/AboutUsPage.js';
import ServicesPage from './Components/ServicesPage/ServicesPage';
import TrainingsPage from './Components/TrainingsPage/TrainingsPage';
import ContactUsPage from './Components/ContactUsPage/ContactUsPage';
import Footer from './Components/Footer/Footer';

function App() {

  let component;
  switch (window.location.pathname) {
    case "/":
      component = <HomePage />;
      break;
    case "/about-us":
      component = <AboutUsPage />;
      break;
    case "/services":
      component = <ServicesPage />;
      break;
    case "/training":
      component = <TrainingsPage />;
      break;
    case "/contact":
      component = <ContactUsPage />;
      break;
    default:
      component = App;
  }

  return (
    <div className="App">
      <Header />
      {component}
      <Footer />
    </div>
  );
}

export default App;

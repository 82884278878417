import ChooseUs from '../ChooseUs/ChooseUs';
import './HomePage.css';

export default function HomePage() {  

    const colleaguesIllustration = require('../../utils/social-colleagues-discuss.png');
    const arrowDown = require('../../utils/arrow-down.png');

    return (
        <>
            <div className="home-page">
                <section className='welcome-page'>
                    <div className='welcome'>
                        <div id="welcome-text"> 
                            <h1>Welcome to J&R United</h1>
                            <h2>Your Trusted IMMEX Partner in Global Trade</h2>
                            <p>At J&R United, we’re dedicated to enhancing your global trade experience. We provide comprehensive solutions for supply chain security, import-export compliance, and more.</p>
                        </div>
                        <img id="illustration" src={colleaguesIllustration} alt="Social colleagues having a discussion." /> 
                    </div>
                    <div className="our-mission">
                        <h2>OUR MISSION</h2>
                        <p>At J&R United, we’re driven by a singular mission - to be your trusted partner in global trade. We’re committed to providing you with the highest quality service and secure solutions in an accessible and comfortable environment.</p>
                    </div>
                    <div className="choose-us-arrow">
                        <h3><b>Why Choose Us</b></h3>
                        <img id="arrow" src={arrowDown} alt="Arrow down" /> 
                    </div>
                </section>
                <section>
                    <ChooseUs></ChooseUs>
                </section>
            </div>
            
        </>
    );
}
import './ContactForm.css'

export default function ContactForm() {
    return (
        <div className="contact-form">
            <h3>Get In Touch</h3>
            <p asterisk="*">Full Name: </p>
            <input type="text"></input>
            <p asterisk="*">Email: </p>
            <input type="text"></input>
            <p asterisk="*">Message: </p>
            <textarea name="textarea"></textarea><br />
            <button className='button' type="submit">SUBMIT</button>
        </div>
    );
}
import ContactForm from '../ContactForm/ContactForm';

import './ContactUsPage.css';

export default function ContactUsPage() {

    const contactIcon = require('../../utils/contact.png');

    return (
        <div className='contact-us-page'>
            <div className="information-icon">
                    <img id="icon" src={contactIcon} alt="Information Icon" /> 
            </div>
            <section className='text'>
                <h1>Contact Us</h1>
                <p><b>Our Partnership for Success</b></p>
                <p>When you choose J&R United, you’re not just selecting a service provider – you’re welcoming a partner into your business. Together, we can fortify your supply chain, ensure compliance, and work towards achieving your success. Contact us today to learn how we can help secure your supply chain and navigate the complexities of international trade regulations, all while being your dedicated partners in your journey to excellence.</p>
            </section>
            <ContactForm />
            <div className='partners'>
                <div className='partner'>
                    <p className='name'>Juan Zarate</p>
                    <p className='title'>Fiscal Defence</p>
                    <p className='contact-info'>Cel: 664-255-5192</p>
                    <p className='contact-info'>e-mail: jzarate@jrunited.net</p>
                </div>
                <div className='partner'>
                    <p className='name'>Alma Sanchez</p>
                    <p className='title'>Import-Export Compliance Specialist</p>
                    <p className='contact-info'>Cel: 664-413-5827</p>
                    <p className='contact-info'>e-mail: asanchez@jrunited.net</p>
                </div>
            </div>
        </ div>
    );
    
}
import Tab from "../Tab/Tab";

import './TrainingsPage.css';

export default function TrainingsPage() {
    return (
    <section className="trainings-page">
        <div className="text">
            <h1>Security and CTPAT / OEA Training Services</h1>
            <p>Our Security and CTPAT / OEA Training Services are designed to empower your organization with the knowledge and tools needed to establish and maintain a secure and compliant supply chain. Contact us to learn more about how our training can strengthen your security measures and CTPAT / OEA certification.</p>
        </div>
        <div className="trainings-offered">
            <Tab
            title="CTPAT & AEO Compliance Workshops"
            description="We offer comprehensive workshops that focus on CTPAT / OEA compliance, helping your team understand the program’s requirements, best practices, and how to implement them effectively."
            verticalAlignment = {true} />
            <Tab
            title="Risk Assessment and Mitigation"
            description="Our training includes in-depth guidance on risk assessment and mitigation strategies to secure your supply chain against potential threats, ensuring a safer and more efficient operation."
            verticalAlignment = {true} />
            <Tab
            title="Security Protocol Development"
            description="We assist in developing customized security protocols tailored to your organization’s unique needs. These protocols encompass access control, personnel security, cargo security, and more."
            verticalAlignment = {true} />
            <Tab
            title="Security Awareness Training"
            description="Enhance your team’s awareness of security threats and best practices through specialized training programs. Your staff will be better prepared to identify and respond to potential risks."
            verticalAlignment = {true} />
            <Tab
            title="Physical Security Measures"
            description="Learn about physical security measures to protect your assets and facilities, from securing access points to implementing surveillance systems."
            verticalAlignment = {true} />  
            <Tab
            title="Technology Integration"
            description="Stay up-to-date with the latest security technologies and their integration into your supply chain operations. Our training covers areas such as RFID, GPS tracking, and biometrics."
            verticalAlignment = {true} />
            <Tab
            title="Incident Response Planning"
            description="Prepare for security incidents with effective incident response planning. We guide you in developing and testing your response strategies to minimize disruption."
            verticalAlignment = {true} />  
            <Tab
            title="CTPAT & OEA Certification Assistance"
            description="We provide comprehensive support for CTPAT / OEA certification, guiding you through the application process, helping you meet the security criteria, and preparing for the validation process."
            verticalAlignment = {true} />  
            <Tab
            title="Customized Training Programs"
            description="Our training can be tailored to your industry, ensuring that you receive industry specific guidance on security and CTPAT / OEA compliance."
            verticalAlignment = {true} />  
            <Tab
            title="Continuous Education and Updates"
            description="Stay current with evolving security regulations and CTPAT / OEA requirements. We offer ongoing education and updates to keep your organization compliant."
            verticalAlignment = {true} />  
            <Tab
            title="Mock Audits and Assessments"
            description="Test your readiness for CTPAT / OEA validation with mock audits and assessments. Identify areas for improvement and fine-tune your security measures."
            verticalAlignment = {true} />  
            <Tab
            title="Compliance Documentation"
            description="We assist in creating and maintaining the necessary documentation for CTPAT / OEA compliance, ensuring that you have a well-organized record of your security practices."
            verticalAlignment = {true} />  
        </div>
    </section>);
}
import Tab from "../Tab/Tab";

import './AboutUsPage.css'

export default function AboutUsPage () {

    const informationIcon = require('../../utils/information-button.png');

    return (
        <div className="about-us-page">
            <div className="about-us-text">
                <div className="information-icon">
                    <img id="icon" src={informationIcon} alt="Information Icon" /> 
                </div>
                <div className="text">
                    <h1>About Us</h1>
                    <p><b>Your Trusted Supply Chain Security and Import-Export Compliance Specialist</b></p>
                    <p>At J&R United, we are more than just a company; we’re a team of dedicated professionals committed to fortifying your supply chain and ensuring compliance in international trade. With years of experience in CTPAT Certifications, loss prevention, and Authorized Economic Operator (OEA) compliance, we have been the trusted choice of numerous organizations on their journey to success.</p>
                </div>
            </div>
            <div className="about-us-elements">
                <Tab
                title="Our Commitment to Security"
                description="Securing your supply chain is our paramount mission. We understand the importance of safeguarding your assets and ensuring the efficiency of your operations. Our expertise in CTPAT Certifications and loss prevention extends beyond securing your goods; it secures your peace of mind."
                verticalAlignment = {false} />
                <Tab
                title="Navigating International Trade"
                description="In today’s global marketplace, navigating international trade regulations is a complex endeavour. That’s where we come in. We specialize in advising on Free Trade Agreements and various import-export regulations to help you traverse the intricate landscape of global commerce."
                verticalAlignment = {false} />
                <Tab
                title="IMMEX Compliance in Mexico"
                description="As part of our comprehensive suite of services, we are uniquely positioned to assist clients with IMMEX (Maquiladora) compliance in Mexico. Our experts are well-versed in the intricacies of IMMEX regulations, ensuring that your operations on both sides of the border meet the required standards and benefit from cost-saving opportunities."
                verticalAlignment = {false} />
            </div>
            <div className="partners-in-success">
                <h1>Your partners in success</h1>
                <p>Choosing J&R United means welcoming a dedicated partner into your business. Together, we can fortify your supply chain, ensure compliance with international trade regulations, and work towards the success you aspire to achieve.</p>
            </div>
        </div>
    );
}
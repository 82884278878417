import { useState } from 'react';

import  Navbar  from '../Navbar/Navbar';
import './Header.css';

export default function Header () {

    const [ isMenuOpen, setIsMenuOpen] = useState(false)

    const logo = require('../../utils/JRUnited-logo.png');

    function handleMenu() {
        setIsMenuOpen(!isMenuOpen);
    }

    console.log(isMenuOpen)

    return (
        <div className={isMenuOpen ? "header open" : "header closed"} >
            <div>
                <img id="logo" src={logo} alt="JR United logo" /> 
                <div className ="vertical-line"></div>
            </div>
            <Navbar isMobileMenuOpen={isMenuOpen} />
            <i onClick={handleMenu} className={isMenuOpen ? 'fa fa-times' : 'fa fa-bars'} />
        </div>
    )
}
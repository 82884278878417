import Navbar from '../Navbar/Navbar';
import './Footer.css';

export default function Footer () {

    const logo = require('../../utils/JRUnited-logo.png');

    return (
        <div className="footer">
            <img id="logo" src={logo} alt="JR United logo" /> 
            <p>Copyright 2023 JRUnited</p>
            <Navbar footer={true}/>
        </div>
    );
}
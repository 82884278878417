import Element from '../Element/Element';

import './ServicesPage.css'

export default function ServicesPage() {

    const lockIcon = require('../../utils/thin-lock.png');
    const importExportIcon = require('../../utils/import.png');
    const agreementIcon = require('../../utils/financial.png');
    const reportIcon = require('../../utils/audit.png');
    const complianceIcon = require('../../utils/risk.png');
    const customsIcon = require('../../utils/customs.png');
    const checklistIcon = require('../../utils/checklist-paper.png');
    const invoiceIcon = require('../../utils/invoice.png');
    const americaIcon = require('../../utils/america.png');

    return(
        <section className='services-page'>
            <h1>Our Comprehensive Services</h1>
            <section className='services-list'>
                <Element 
                title="Supply Chain Security"
                description="Our primary focus is to safeguard your supply chain from potential risks. With a commitment to security and extensive experience in CTPAT Certifications and OEA compliance, we provide robust protection for your global trade operations."
                icon={lockIcon} 
                iconDescription="Icon of a lock"
                smallIcon={true} />
                <Element 
                title="Import-Export Expertise"
                description="Beyond security, we offer a wide range of import-export compliance services to support your business."
                icon={importExportIcon} 
                iconDescription="Truck reaching destination"
                smallIcon={true} />
                <Element 
                title="Free Trade Agreements and Regulations"
                description="We specialize in advising on Free Trade Agreements and various import-export regulations to help you navigate the complexities of international trade."
                icon={agreementIcon} 
                iconDescription="Document with approval icon"
                smallIcon={true} />
                <Element 
                title="Customs Audits and Compliance Reviews"
                description="Our team is skilled in conducting customs audits and compliance reviews to ensure your operations meet regulatory standards."
                icon={reportIcon} 
                iconDescription="Report showing an approval stamp and growth over time"
                smallIcon={true} />
                <Element 
                title="IMMEX/PROSEC & VAT Compliance"
                description="We're proficient in IMMEX/PROSEC and VAT certification compliance, providing you with expert risk analysis and guidance."
                icon={complianceIcon} 
                iconDescription="Meter measuring risk"
                smallIcon={true} />
                <Element 
                title="Customs Compliance Audits"
                description="We perform annual customs compliance audits of Annex 24 and Annex 30, ensuring your operations align with customs regulations."
                icon={customsIcon} 
                iconDescription="Icon of a customs officer"
                smallIcon={true} />
                <Element 
                title="Customs Audit Assistance"
                description="We have a proven track record of successfully assisting companies in managing customs audits and requests."
                icon={checklistIcon} 
                iconDescription="Icon of a checklist being ticked"
                smallIcon={true} />
                <Element 
                title="Duty Deferral Programs"
                description="We're well-versed in duty deferral programs, USMCA/EU and PROSEC, helping you optimize your cost-saving
                opportunities."
                icon={invoiceIcon} 
                iconDescription="Icon of an invoice being printed"
                smallIcon={true} />
                <Element 
                title="CTPAT/OEA Expertise"
                description="Our proficiency extends to CTPAT/OEA implementation, applications, training, and compliance audits."
                icon={americaIcon} 
                iconDescription="Icon of North America and South America"
                smallIcon={true} />
            </section>
        </ section>
    );
}
import './Element.css';

export default function Element({title, description, icon, iconDescription, smallIcon}) {

    if(smallIcon) {
        return(
            <div className='element'>
                <img className="small-icon" src={icon} alt={iconDescription} />
                <div className='text'>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        );
    }
    else {
        return(
            <div className='element dark-bg'>
                <img className="icon" src={icon} alt={iconDescription} />
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        );
    }
}